<template>

  <div>
    <b-card>
      <b-card-text>
        <h3>Regulamin</h3>
        <h4>§ 1. SŁOWNIK POJĘĆ</h4>

        <p>
          <strong>Pojęciom, jak niżej przypisano w Regulaminie następujące znaczenie:</strong><br />
          <strong>UG2BS</strong> – UG2BS Sp. z o.o, NIP: 584 275 02 47, REGON: 364604859, adres: ul. Kołobrzeska, nr 69B, lok. 11, 80-396 Gdańsk.<br />
          <strong>Platforma</strong> – serwis internetowy funkcjonujący pod adresem www.ug2bs.pl; poprzez Platformę umożliwia się Klientowi korzystanie z Usług oraz zawieranie Umów;<br />
          <strong>Użytkownik</strong> – konsument w rozumieniu art. 221 Kodeksu cywilnego korzystający z Platformy;<br />
          <strong>Materiały</strong> – treści publikowane lub udostępniane poprzez Platformę, m.in. w serwisie VIMEO, w dowolnej formie, w szczególności treści audiowizualne, tekstowe, graficzne, fotograficzne oraz dźwiękowe;<br />
          <strong>Usługi</strong> – usługi udzielania odpłatnego dostępu do wybranych przez Użytkownika Materiałów;<br />
          <strong>Towary</strong> – rzeczy ruchome sprzedawane przez UG2BS;<br />
          Umowy – umowy zawierane na odległość między UG2BS i Użytkownikiem, których przedmiotem może być:<br />
          świadczenie przez UG2BS usług drogą elektroniczną, polegających na odpłatnym udzieleniu dostępu do treści audiowizualnych poprzez Platformę (Umowa o świadczenie Usług);<br />
          sprzedaż rzeczy oferowanych przez UD2BS w ramach Serwisu (Umowa sprzedaży towarów – rzeczy ruchomych);Treść Umów wyznaczają postanowienia Regulaminu;<br />
          Regulamin określa zasady zawierania Umów oraz korzystania z Platformy.
        </p>
        <h4>§ 2. REJESTRACJA I DOSTĘP</h4>
        <p>
          a. Użytkownik rejestrując konto w ramach Platformy potwierdza, że zapoznał się Regulaminem oraz akceptuje zawarte w nim postanowienia. Rejestracja poprzez podanie wszelkich wymaganych danych jest konieczna dla uzyskania dostępu do Platformy.<br />
          b. Następstwem rejestracji Użytkownika w ramach Platformy, wyboru rodzaju Umowy i uiszczenia wymaganej opłaty dochodzi do zawarcia Umowy pomiędzy Użytkownikiem a UG2BS.<br />
          c. W razie wystąpienia przyczyn niezależnych od UG2BS, termin rejestracji na Platformie może ulec wydłużeniu.<br />
          d. Użytkownik ma obowiązek korzystania z Platformy i Materiałów w sposób zgodny z Regulaminem oraz obowiązującymi przepisami prawa.<br />
          e. Użytkownik może korzystać z Platformy przebywając na terytorium Rzeczypospolitej Polskiej. Dostępność Platformy, w tym Materiałów, dla Użytkownika przebywającego poza terytorium Rzeczypospolitej Polskiej może być ograniczona w całości lub części.<br />
          f. Platforma, w tym Materiały, są dostępne wyłącznie na urządzeniach spełniających wymagania techniczne określone w Regulaminie.<br />
          g. Użytkownik uzyskuje dostęp do aktualnej na dany moment zawartości Platformy. UG2BS może zmienić zawarte na Platformie dane, w tym Materiały, w dowolnie wybranym przez siebie momencie, co nie stanowi zmiany Umowy zawartej z Użytkownikiem.
        </p>
        <h4>§ 3. KORZYSTANIE Z PLATFORMY</h4>
        <p>
          1. Do korzystania z Platformy niezbędne jest, aby Użytkownik posiadał dostęp do Internetu, korzystał z urządzenia posiadającego przeglądarkę internetową, dysponował skrzynką e-mail, dysponował łączem internetowym pozwalającym na oglądanie filmów.<br />
          2. Do korzystania z Platformy niezbędne jest posiadanie urządzeń spełniających minimalne wymagania sprzętowe:Komputer lub inne urządzenie z dostępem do Internetu; Dostęp do poczty elektronicznej; Przeglądarka internetowa: Mozilla Firefox w wersji 56.0 lub nowszej z włączoną obsługą JavaScript i cookies; albo Google Chrome w wersji 61.0 lub nowszej, Opera w wersji 38, Edge, Safari 11. Zainstalowane aktualne sterowniki karty graficznej, dostarczone przez producenta komputera.<br />
          3. Ze względu na niezależne od UG2BS właściwości urządzeń, którymi dysponuje Użytkownik, korzystanie z Platformy oraz dostęp do Materiałów może być ograniczony lub uniemożliwiony.<br />
          4. Korzystanie z Platformy wymaga zapisywania plików cookies.
        </p>

        <h4>§ 4. UMOWA O ŚWIADCZENIE USŁUG</h4>
        <p>
          1. W ramach Platformy dostępna jest możliwość zakupu abonamentu na Usługę w modelu subskrypcji lub Usługi, w ramach której możliwe będzie pobranie wybranych Materiałów.<br />
          2. Przez subskrypcję należy rozumieć usługę sprzedaży abonamentu na dostęp do Usługi w określonym czasie. Przez udostępnienie Materiałów do pobrania należy rozumieć umożliwienie Użytkownikowi pobrania wybranych Materiałów.<br />
          3. Subskrypcja jednorazowa na wskazany okres jest umową na czas określony i kończy się wraz z ostatnim dniem okresu na jaki została zawarta.<br />
          4. Za dokonanie zapłaty wynagrodzenia za dostęp do Usług uważa się moment uznania rachunku bankowego wskazanego przez UG2BS.<br />
          5. Dostęp do wybranych Materiałów nastąpi niezwłocznie po otrzymaniu przez UG2BS potwierdzenia dokonania opłaty.<br />
          6. Płatności za dostęp do Usług można dokonać w sposób podany na Platformie.<br />
          7. W ramach zamawiania Usług Użytkownik w dodatkowym checkboxie wyraża następującą zgodę: “Wyrażam zgodę na dostarczenie treści cyfrowych, które nie są zapisane na nośniku materialnym lub na rozpoczęcie świadczenia usługi przed upływem 14 dni od dnia zawarcia umowy i przyjmuję do wiadomości utratę prawa do odstąpienia od umowy”. UG2BS potwierdzi otrzymanie ww. zgody mailowo.<br />
          8. W ramach zamówienia Usługi Użytkownik w dodatkowym checkboxie potwierdza złożenie oświadczenia o następującej treści: “Oświadczam niniejszym, że wiem, iż w ramach korzystania z Materiałów, do których uzyskałem dostęp, nie jestem uprawniony do ich publicznego prezentowania, rozpowszechniania, udostępniania i wszelkiego innego wykorzystywania, nagrywania lub innego utrwalania, sprzedaży lub jakiegokolwiek innego dysponowania nimi, poza odtwarzaniem w ramach prywatnego użytku”.
        </p>

        <h4>§ 5. MATERIAŁY</h4>
        <p>
          1. Korzystanie z Platformy, w tym z Materiałów, jest dopuszczalne wyłącznie dla celów prywatnych, bez możliwości ich dalszego rozpowszechniania, w szczególności w celach zarobkowych.<br />
          2. W ramach Usług UG2BS udziela Użytkownikowi niezbywalnej, niewyłącznej i ograniczonej licencji na odtwarzanie Materiałów Audiowizualnych wyłącznie dla Użytkownika (na własny użytek), co wyłącza możliwość publicznego prezentowania, rozpowszechniania, udostępniania i wszelkiego innego wykorzystywania Materiałów, nagrywania lub innego utrwalania Materiałów, sprzedaży lub jakiegokolwiek innego dysponowania Materiałami.<br />
          3. Użytkownik jest zobowiązany do korzystania z udostępnionych 4. Materiałów w sposób zgodny z prawem.<br />
          Materiały nie są źródłem wiedzy medycznej, a stosowanie zawartych w nich porad uzależnione jest od samodzielnej decyzji Użytkownika. UG2BS nie odpowiada za stan zdrowia Użytkownika przed skorzystaniem z porad zawartych w Materiałach. Przed rozpoczęciem korzystania z treningów i porad konieczne może być skonsultowanie się z lekarzem.
        </p>

        <h4>§ 6. ODEBRANIE DOSTĘPU UŻYTKOWNIKOWI</h4>
        <p>
          Dostęp do Platformy lub Materiałów może zostać odebrany niezwłocznie w przypadku, gdy Użytkownik wykorzystuje Serwis lub Materiały w sposób niezgodny z prawem lub z Regulaminem.
        </p>

        <h4>§ 7. ODSTĄPIENIE OD UMOWY O ŚWIADCZENIE USŁUG</h4>
        <p>
          1. Użytkownik będący konsumentem może, stosownie do treści art. 27 ustawy z dnia 30 maja 2014 r. o prawach konsumenta – bez podania przyczyny – odstąpić od Umowy w terminie 14 dni od dnia zawarcia Umowy. Prawo odstąpienia nie przysługuje w przypadkach określonych w art. 38 ustawy z dnia 30 maja 2014 r. o prawach konsumenta, w tym w przypadku gdy spełnianie świadczenia rozpoczęło się za wyraźną zgodą konsumenta przed upływem terminu do odstąpienia od Umowy i po poinformowaniu go o utracie prawa odstąpienia od Umowy o świadczenie usług.
          2. W przypadku Umowy o świadczenie Usług, która jest zawierana na odległość, UG2BS rozpoczyna świadczenie Usług po otrzymaniu od Użytkownika wyraźnej zgody na rozpoczęcie świadczenia przed upływem terminu odstąpienia. Oświadczenie obejmujące powyższą zgodę jest składane przez Użytkownika przy zakupie danej Usługi.
        </p>

        <h4>§ 8. UMOWA SPRZEDAŻY</h4>
        <p>
          1. Zamieszczone na Platformie opisy Towarów i ceny stanowią zaproszenie do zawarcia umowy w rozumieniu art. 71 kodeksu cywilnego. Charakter wiążący – na potrzeby zawarcia konkretnej umowy – zyskują one dopiero z chwilą potwierdzenia przez UG2BS zamówienia w formie maila.<br />
          2. Złożenia zamówienia na dane Towary można dokonać po rejestracji na Platformie.<br />
          3. Zakupu dokonuje się poprzez wypełnienie formularza zamówienia dostępnego na Platformie. Formularz zamówienia określa w szczególności jakie Towary, po jakiej cenie i w jakich ilościach Użytkownik chce zamówić do wskazanej przez siebie lokalizacji. Użytkownik podejmuje odpowiednie kroki techniczne w oparciu o wyświetlane komunikaty.<br />
          4. Wysłanie przez Użytkownika formularza zamówienia stanowi wiążące oświadczenie woli zawarcia Umowy sprzedaży zawartej na odległość, zgodnie z treścią niniejszego Regulaminu.<br />
          5. Umowę sprzedaży na odległość traktuje się jako zawartą w chwili opłacenia zamówienia przez Użytkownika.<br />
          6. Płatności za wybrany Towar można dokonać w sposób wybrany w momencie składania zamówienia.<br />
          7. Czas realizacji Zamówienia wynosi od 1 do 7 dni roboczych licząc od dnia zawarcia umowy.<br />
          8. W ramach formularza zamówienia Użytkownik dokonuje wyboru sposobu dostawy poprzez zaznaczenie dokonanego wyboru.<br />
          9. W przypadku, gdy nie dojdzie do odbioru Towaru przez Użytkownika, skutkiem czego Towar wróci do UG2BS, umowa taka uważana będzie za niezawartą. W takim przypadku UG2BS zobowiązany jest niezwłocznie zwrócić Użytkownikowi otrzymaną płatność za zakupiony przez Użytkownika Towar.
        </p>

        <h4>§ 9. ODSTĄPIENIE OD UMOWY</h4>
        <p>
          1. Odstąpienie od Umowy następuje po złożeniu przez Użytkownika stosownego oświadczenia o odstąpieniu dokonanego na piśmie na adres UG2BS wskazany w § 1 lit. a. Regulaminu.<br />
          2. Oświadczenie można złożyć zgodnie z pouczeniem o odstąpieniu od umowy, stanowiącym załącznik nr 1 do Regulaminu, na formularzu, którego wzór stanowi załącznik nr 2 do niniejszego Regulaminu.<br />
          3. W razie odstąpienia od Umowy, Użytkownik, powinien dostarczyć kserokopię otrzymanych faktury VAT lub paragonu.<br />
          4. Prawo do odstąpienia od Umowy przysługuje także osobie fizycznej prowadzącej jednoosobową działalność gospodarczą dokonującą zakupu o charakterze niezawodowym.<br />
          5. Prawo odstąpienia od Umowy sprzedaży zawartej na odległość nie przysługuje w odniesieniu do umów wskazanych w art. 38 ustawy z dnia 30 maja 2014 r. o prawach konsumenta, tj. m.in. umowy:<br />
          a. w której cena lub wynagrodzenie zależy od wahań na ryku finansowym, nad którymi przedsiębiorca nie sprawuje kontroli, i które mogą wystąpić przed upływem terminu do odstąpienia od umowy;<br />
          b. których przedmiotem świadczenia jest rzecz nieprefabrykowana, wyprodukowana według specyfikacji Klienta lub służąca zaspokojeniu jego zindywidualizowanych potrzeb;<br />
          c. których przedmiotem świadczenia jest rzecz dostarczana w zapieczętowanym opakowaniu, której po otwarciu opakowania nie można zwrócić ze względu na ochronę zdrowia lub ze względów higienicznych, jeżeli opakowanie zostało otwarte po dostarczeniu;<br />
          d. których przedmiotem świadczenia są nagrania dźwiękowe lub wizualne albo programy komputerowe dostarczane w zapieczętowanym opakowaniu, jeżeli opakowanie zostało otwarte po dostarczeniu;<br />
          e. o dostarczanie treści cyfrowych oraz licencji elektronicznych, które nie są zapisane na nośniku materialnym, jeżeli spełnianie świadczenia rozpoczęło się za wyraźną zgodą Konsumenta przed upływem terminu do odstąpienia od umowy i po poinformowaniu go przez przedsiębiorcę o utracie prawa odstąpienia od umowy;<br />
          f. których przedmiotem jest rzecz ulegająca szybkiemu zepsuciu lub mająca krótki termin przydatności do użycia, oraz w której przedmiotem świadczenia są rzeczy, które po dostarczeniu, ze względu na swój charakter, zostają nierozłącznie połączone z innymi rzeczami;<br />
          g. o dostarczanie dzienników, periodyków lub czasopism, z wyjątkiem umowy o prenumeratę;<br />
          h. zawartej w drodze aukcji publicznej;<br />
          i. o świadczenie usług w zakresie zakwaterowania, innych niż do celów mieszkalnych, przewozu rzeczy, najmu samochodów, gastronomii, usług związanych z wypoczynkiem, wydarzeniami rozrywkowymi, sportowymi lub kulturalnymi, jeżeli w umowie oznaczono dzień lub okres świadczenia usługi;<br />
          j. w której przedmiotem świadczenia są napoje alkoholowe, których cena została uzgodniona przy zawarciu umowy sprzedaży, a których dostarczenie może nastąpić dopiero po upływie 30 dni i których wartość zależy od wahań na rynku, nad którymi przedsiębiorca nie ma kontroli;<br />
          <br /><br />
          6. Skutki odstąpienia od Umowy sprzedaży:<br />
          a. UG2BS w ciągu 14 dni od dnia otrzymania oświadczenia o odstąpieniu od Umowy sprzedaży rzeczy, zwróci Użytkownikowi wszystkie dokonane przez niego płatności;<br />
          b. Zwrot płatności zostanie dokonany przy użyciu takiego samego sposobu zapłaty, jakiego użył Użytkownik.<br />
          c. Jeżeli UG2BS nie zaproponował, że sam odbierze Towar od Użytkownika, może wstrzymać się ze zwrotem płatności otrzymanych od Użytkownika do chwili otrzymania Towaru z powrotem.<br />
          d. UG2BS może zaproponować Użytkownikowi, że sam odbierze od niego rzecz. Jeżeli jednak UG2BS nie złożył takiej propozycji – Użytkownik powinien zwrócić rzecz UG2BS niezwłocznie, jednak nie później niż 14 dni od dnia, w którym odstąpił od umowy. Do zachowania terminu wystarczy odesłanie rzeczy przed jego upływem. Towar, który Użytkownik zwraca powinien wysyłać go na adres UG2BS.<br />
          <br /><br />
          7. Użytkownik jest odpowiedzialny za zmniejszenie wartości Towaru będące wynikiem korzystania z niego w sposób wykraczający poza konieczny do stwierdzenia charakteru, cech i funkcjonowania Towaru.

        </p>

        <h4>§ 10. REKLAMACJE</h4>
        <p>
          1. Reklamacje dotyczące działania Platformy, Usług, Materiałów oraz Towarów mogą być zgłaszane przez Użytkownika na adres UG2BS.<br />
          2. W ramach reklamacji Użytkownik powinien wskazać przynajmniej: imię i nazwisko Użytkownika oraz adres e-mail służący dostępowi do Platformy, a także inne niezbędne dane kontaktowe, przedmiot reklamacji wraz z uzasadnieniem, a także podpis. Brak danych umożliwiających kontakt z Użytkownikiem skutkuje pozostawieniem reklamacji bez rozpoznania.<br />
          3. Wpływ reklamacji zostaje niezwłocznie potwierdzony, zaś odpowiedź zostanie udzielona nie później w ciągu 30 dni od dnia otrzymania prawidłowo zredagowanej reklamacji lub w ciągu 14 dni, gdy zastosowanie znajdą przepisy dotyczące rękojmi zredagowane w Kodeksie Cywilnym.<br />
          4. Zgłoszenie reklamacji nie skutkuje zwolnieniem Użytkownika z obowiązku bieżącego regulowania wymagalnych płatności.<br />
          5. Użytkownik może korzystać z pozasądowej możliwości rozwiązywania sporów drogą elektroniczną za pośrednictwem platformy internetowej ODR Unii Europejskiej umożliwiającej składanie reklamacji i dochodzenie roszczeń związanych z zawartą z Użytkownikiem Umową, która znajduje się na stronie: https://ec.europa.eu/consumers/odr/.
        </p>

        <h4>§ 11. POUCZENIA I POSTANOWIENIA KOŃCOWE</h4>
        <p>
          1. Użytkownikowi nie przysługują jakiekolwiek prawa, w tym prawa autorskie bądź pokrewne do Platformy lub Materiałów.<br />
          2. Użytkownik nie jest uprawniony do jakiegokolwiek utrwalania, zwielokrotniania, udostępniania, upubliczniania bądź rozpowszechniania treści zamieszczonej na Platformie, bądź uzyskanej poprzez Platformę, chyba, że takie uprawnienie wynika z przepisów prawa lub Regulaminu.<br />
          3. Prawem właściwym dla rozstrzygania wszelkich sporów związanych z Regulaminem jest prawo polskie.<br />
          4. Użytkownik ma prawo dochodzenia roszczeń w postępowaniu sądowym przed właściwym miejscowo i rzeczowo sądem, bez konieczności korzystania z sądownictwa polubownego.<br />
          5. Użytkownik może skorzystać z pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń. Wszelkie informacje dotyczące pozasądowych sposobów rozpatrywania reklamacji i dochodzenia roszczeń można uzyskać na stronie internetowej Urzędu Ochrony Konkurencji i Konsumentów pod adresem: www.uokik.gov.pl.<br />
          6. Pod adresem https://ec.europa.eu/consumers/odr dostępna jest platforma internetowego systemu rozstrzygania sporów pomiędzy konsumentami i przedsiębiorcami na szczeblu unijnym (platforma ODR). Platforma ODR stanowi stronę internetową z punktem kompleksowej obsługi dla konsumentów i przedsiębiorców dążących do pozasądowego rozstrzygnięcia sporu dotyczącego zobowiązań umownych wynikających z internetowej umowy sprzedaży lub umowy o świadczenie usług.
        </p>

        <h5>Załącznik nr 1 do Regulaminu</h5>
        <p>
          <strong>
            INFORMACJE DOTYCZĄCE KORZYSTANIA Z PRAWA ODSTĄPIENIA OD UMOWY
            WZÓR POUCZENIA O ODSTĄPIENIU OD UMOWY
          </strong>
          Prawo odstąpienia od umowy
          Mają Państwo prawo odstąpić od niniejszej umowy w terminie 14 dni bez podania jakiejkolwiek przyczyny.
          Termin do odstąpienia od umowy wygasa po upływie 14 dni od dnia w którym weszli Państwo w posiadanie rzeczy lub w którym osoba trzecia inna niż przewoźnik i wskazana przez Państwa weszła w posiadanie rzeczy.
          Aby skorzystać z prawa odstąpienia od umowy, muszą Państwo poinformować UG2BS o swojej decyzji o odstąpieniu od umowy w drodze jednoznacznego oświadczenia.
          Mogą Państwo skorzystać z wzoru formularza odstąpienia od umowy, jednak nie jest to obowiązkowe.
          Aby zachować termin do odstąpienia od umowy, wystarczy, aby wysłali Państwo informację dotyczącą wykonania przysługującego Państwu prawa odstąpienia od umowy przed upływem terminu do odstąpienia od umowy.
          Skutki odstąpienia od umowy
          W przypadku odstąpienia od niniejszej umowy zwracamy Państwu wszystkie otrzymane od Państwa płatności, w tym koszty dostarczenia rzeczy (z wyjątkiem dodatkowych kosztów wynikających z wybranego przez Państwa sposobu dostarczenia innego niż najtańszy zwykły sposób dostarczenia oferowany przez nas), niezwłocznie, a w każdym przypadku nie później niż 14 dni od dnia, w którym zostaliśmy poinformowani o Państwa decyzji o wykonaniu prawa odstąpienia od niniejszej umowy. Możemy wstrzymać się ze zwrotem płatności do czasu otrzymania rzeczy lub do czasu dostarczenia nam dowodu jej odesłania, w zależności od tego, które zdarzenie nastąpi wcześniej. Zwrotu płatności dokonamy przy użyciu takich samych sposobów płatności, jakie zostały przez Państwa użyte w pierwotnej transakcji, chyba że wyraźnie zgodziliście się Państwo na inne rozwiązanie; Będą Państwo musieli ponieść bezpośrednie koszty zwrotu rzeczy.
        </p>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {BCard, BCardText} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardText,
  },
  data() {
    return {}
  },
}
</script>

<style type="text/css">
p.p1 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  text-align: center;
  line-height: 37.5px;
  font: 27.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

p.p2 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  text-align: center;
  font: 12.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

p.p3 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  line-height: 28.5px;
  font: 18.0px Helvetica;
  color: #000000;
  background-color: #ffffff;
  min-height: 22.0px
}

p.p4 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  line-height: 28.5px;
  font: 18.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

p.p6 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  font: 12.0px Helvetica;
  color: #000000;
  background-color: #ffffff
}

li.li5 {
  margin: 0.0px 0.0px 5.0px 0.0px;
  font: 12.0px Helvetica;
  color: #000000
}

span.s1 {
  background-color: #ffffff
}

ol.ol1 {
  list-style-type: decimal
}

ol.ol2 {
  list-style-type: lower-alpha
}
</style>
